import React, { forwardRef } from 'react';
import { motion } from 'framer-motion';

const Card = forwardRef(({ link, title, subtitle, children, className = '', onMouseEnter, onMouseLeave }, ref) => {
  return (
      <motion.div 
        ref={ref}
        className={`max-w-lg pb-6 text-center ${className} w-full rounded-[40px] overflow-hidden`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        whileHover={{ 
        scale: 1.02,
        transition: { duration: 0.2 }
      }}
    >
          <a href={link} target="_blank" rel="noopener noreferrer">
      {children}

      {title && (
        <h2 className="text-[20px] text-white font-diatype mb-2">{title}</h2>
      )}
      {subtitle && (
          <p className="text-white text-[15px] font-diatype mb-4 max-w-[250px] mx-auto opacity-70">{subtitle}</p>
        )}
            </a>
      </motion.div>
  );
});

Card.displayName = 'Card';
export default Card; 