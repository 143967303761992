import { useState, useEffect, useRef, memo } from 'react';
import { IPadCursorProvider, useIPadCursor } from "ipad-cursor/react";
import { motion, useInView } from "framer-motion";
import Card from './components/Card';
import './assets/fonts/fonts.css';
import './App.css';
import Waveform from './components/Waveform';
import { initCursor, disposeCursor, updateCursor } from 'ipad-cursor';

// const DynamicIsland = memo(() => {
//   const [time, setTime] = useState('');

//   useEffect(() => {
//     const updateTime = () => {
//       const options = { 
//         timeZone: 'America/Toronto',
//         hour: 'numeric',
//         minute: 'numeric',
//         hour12: true
//       };
//       const estTime = new Date().toLocaleString('en-US', options);
//       setTime(estTime);
//     };

//     updateTime();
//     const interval = setInterval(updateTime, 1000);
//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <motion.div 
//       className="inline-block bg-[#262626] rounded-full px-5 h-12 mb-12 text-white text-sm flex font-diatype font-bold max-w-sm mx-auto"
//       whileHover={{ 
//         scale: 1.02,
//         backgroundColor: "#2d2d2d",
//         transition: { duration: 0.2 }
//       }}
//       whileTap={{ scale: 0.98 }}
//       initial={{ opacity: 0, scale: 0.9 }}
//       animate={{ opacity: 1, scale: 1 }}
//       transition={{ duration: 0.6, ease: "easeInOut" }}
//     >
//       <div className="flex flex-row items-center justify-between w-full gap-4">
//         <motion.img 
//           src="/after-hours.jpg" 
//           alt="Description" 
//           className="w-8 h-8 rounded-md"
//           whileHover={{ scale: 1.1 }}
//           whileTap={{ scale: 0.95 }}
//         />               
//         <motion.p 
//           className="text-white text-sm"
//           animate={{ opacity: [1, 0.7, 1] }}
//           transition={{ 
//             duration: 2, 
//             repeat: Infinity,
//             ease: "easeInOut" 
//           }}
//         >
//           {time}
//         </motion.p>
//         <Waveform 
//           lineCount={5} 
//           maxHeight={20} 
//           minHeight={4} 
//           width={1.5} 
//           gap={2}
//           color="rgba(255,255,255,1.0)" 
//         />
//       </div>
//     </motion.div>
//   );
// });

const DynamicIsland = memo(() => {
  const [time, setTime] = useState('');

  useEffect(() => {
    const updateTime = () => {
      const options = { 
        timeZone: 'America/Toronto',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      };
      const estTime = new Date().toLocaleString('en-US', options);
      setTime(estTime);
    };

    updateTime();
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <motion.div 
      className="inline-block bg-[#262626] rounded-full px-5 h-12 mb-12 text-white text-sm flex font-diatype font-bold max-w-sm mx-auto"
      whileHover={{ 
        scale: 1.02,
        backgroundColor: "#2d2d2d",
        transition: { duration: 0.2 }
      }}
      whileTap={{ scale: 0.98 }}
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.6, ease: "easeInOut" }}
    >
      <div className="flex items-center justify-between w-full gap-4">
        <span className="text-white/60 text-sm">Toronto</span>
        <motion.p 
          className="text-white text-sm"
        >
          {time}
        </motion.p>
        <div className="relative w-2 h-2">
          <motion.div 
            className="absolute inset-0 rounded-full bg-green-500"
            animate={{ 
              scale: [0, 0.5, 2],
              opacity: [0, 1, 0]
            }}
            transition={{ 
              duration: 2,
              repeat: Infinity,
              times: [0, 0.15, 1],
              ease: "linear"
            }}
          />
          <div 
            className="absolute inset-0 rounded-full bg-green-500"
          />
        </div>
      </div>
    </motion.div>
  );
});

// Memoize Profile component
const Profile = memo(() => {
  return (
    <div>
      <motion.div 
        whileHover={{ 
          scale: 1.02,
          transition: { duration: 0.2 }
        }}
        whileTap={{ scale: 0.98 }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, ease: "easeInOut", delay: 1.2 }}
      >
        <img 
          src="/Memoji.gif" 
          alt="Memoji" 
          className="w-48 h-48 md:w-64 md:h-64 mb-[-20px] mx-auto mt-[-30px] md:mt-[-70px] bg-transparent object-contain"
          style={{
            WebkitMaskImage: '-webkit-radial-gradient(white, black)',
            backfaceVisibility: 'hidden'
          }}
        />
      </motion.div>
      
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, ease: "easeInOut", delay: 1.8 }}
      >
        <h1 data-cursor="text" className="text-white text-[18px] md:text-[20px] font-diatype mb-4">Mohammed Ibrahim</h1>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, ease: "easeInOut", delay: 2.2 }}
      >
          <h3 data-cursor="text" className="text-white text-[18px] md:text-[20px] font-diatype mb-8 opacity-60">Software engineer and<br/>designer based in Toronto</h3>
        </motion.div>

      <motion.div
        className="flex justify-center gap-4 mt-8"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1.0 }}
        transition={{ duration: 0.6, ease: "easeInOut", delay: 2.6 }}
      >
        <a href="https://x.com/mohams2001" target="_blank" rel="noopener noreferrer">
          <motion.img 
            data-cursor="block"
            src="/icons/x.svg" 
            alt="X (Formerly Twitter)" 
            className="w-8 h-8 p-2 filter grayscale" 
            initial={{ opacity: 0.5 }}
            whileHover={{ opacity: 1 }}
          />
        </a>
        <a href="https://github.com/ibruthecreator" target="_blank" rel="noopener noreferrer">
          <motion.img 
            data-cursor="block"
            src="/icons/github.svg" 
            alt="GitHub" 
            className="w-8 h-8 p-2 filter grayscale" 
            initial={{ opacity: 0.5 }}
            whileHover={{ opacity: 1 }}
          />
        </a>
        <a href="https://linkedin.com/in/mohammed2ibrahim" target="_blank" rel="noopener noreferrer">
          <motion.img 
            data-cursor="block"
            src="/icons/linkedin.svg" 
            alt="LinkedIn" 
            className="w-8 h-8 p-2 filter grayscale" 
            initial={{ opacity: 0.5 }}
            whileHover={{ opacity: 1 }}
          />
        </a>
        <a href="https://instagram.com/mohams3ios01" target="_blank" rel="noopener noreferrer">
          <motion.img 
            data-cursor="block"
            src="/icons/instagram.svg" 
            alt="Instagram" 
            className="w-8 h-8 p-2 filter grayscale" 
            initial={{ opacity: 0.5 }}
            whileHover={{ opacity: 1 }}
          />
        </a>
      </motion.div>

      <motion.div
        data-cursor="block"
        className="inline-flex items-center gap-2 mt-4 px-2"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1.0 }}
        transition={{ duration: 0.6, ease: "easeInOut", delay: 2.6 }}
      >
        <a href="/files/Resume.pdf" target="_blank" rel="noopener noreferrer" className="text-white flex items-center gap-2">
          <span className="font-diatype text-[18px]">Resume</span>
          <img 
            src="/icons/top-right-arrow.png" 
            alt="Top Right Arrow" 
            className="w-5 h-5 filter invert"
          />
        </a>
      </motion.div>
      </div>
    );
  });

// Memoize About component
const About = memo(() => {
  return (
    <div className="mt-8 md:mt-0">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, ease: "easeInOut", delay: 2.6 }}
        >
          <p data-cursor="text" className="text-white text-[18px] md:text-[25px] font-diatype font-[300] mb-6 text-center md:text-left" style={{ lineHeight: '1.6' }}>From the age of 13, I started building software. I opened a web design agency, cold-calling small businesses in my city and building websites.</p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, ease: "easeInOut", delay: 3.0 }}
        >
          <p data-cursor="text" className="text-white text-[18px] md:text-[25px] font-diatype font-[300] mb-6 text-center md:text-left" style={{ lineHeight: '1.6' }}>Since then, I've taken an extreme interest in building mobile apps for iOS. I enjoy designing everything from the user interface to the backend architecture of any project I take on.</p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, ease: "easeInOut", delay: 3.4 }}
        >
          <p data-cursor="text" className="text-white text-[18px] md:text-[25px] font-diatype font-[300] mb-6 text-center md:text-left" style={{ lineHeight: '1.6' }}>I build with craft in mind and strive to build the best user experiences.</p>
        </motion.div>
    </div>
  );
});

const PreviouslyWorked = memo(() => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });

  return (
    <div className="w-full flex justify-center mt-20" ref={ref}>
      <div className="max-w-5xl w-full px-4 md:px-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.6, ease: "easeInOut" }}
        >
          <p data-cursor="text" className="text-white text-[18px] md:text-[20px] font-diatype mb-6 text-left" style={{ lineHeight: '1.6' }}>Previously</p>
        </motion.div>

        <div>
          {[
            {
              company: "Boom",
              title: "Founding Software Engineer",
              description: "Developed a macOS camera application integrating video processing, real-time overlays, and a custom rendering pipeline with major videoconferencing platforms.",
              image: "/icons/boom.png",
              startDate: "Nov 2022",
              endDate: "Jan 2025",
              delay: 0.4
            },
            {
              company: "Apple",
              title: "Software Engineer",
              description: "Prototyped iOS features focused on novel user interactions to enhance language translation capabilities.",
              image: "/icons/apple.png",
              startDate: "May",
              endDate: "Aug 2023",
              delay: 0.6
            },
            {
              company: "Twitter",
              title: "Software Engineer",
              description: "Created mixed-media tweet functionality with real-time metrics, an enhanced draft management system with cross-device synchronization.",
              image: "/icons/twitter.png",
              startDate: "May",
              endDate: "Aug 2022",
              delay: 0.8
            },
            {
              company: "Apple",
              title: "Software Engineer",
              description: "Built image and live video translation for the iOS Translate app, coupled with a benchmarking system to evaluate performance and quality.",
              image: "/icons/apple.png",
              startDate: "May",
              endDate: "Sep 2021",
              delay: 1.0
            },
            {
              company: "Shopify",
              title: "Software Engineer",
              description: "Launched new order and fulfillment features in the Shopify mobile app, improved load performance and user experience.",
              image: "/icons/shopify.png",
              startDate: "Jan",
              endDate: "Apr 2021",
              delay: 1.2
            },
            {
              company: "Shopify",
              title: "Software Engineer",
              description: "Built an event tracking feature for the Shopify POS app, which allowed managers to approve or reject staff requests, with event tracking and analytics.",
              image: "/icons/shopify.png",
              startDate: "Sep",
              endDate: "Dec 2020",
              delay: 1.4
            }
          ].map((workItem, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
              transition={{ duration: 0.6, ease: "easeInOut", delay: isInView ? index * 0.1 : 0 }}
            >
              <WorkItem 
                company={workItem.company}
                title={workItem.title}
                description={workItem.description}
                image={workItem.image}
                startDate={workItem.startDate}
                endDate={workItem.endDate}
              />
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
});

const WorkItem = memo(({ company, title, description, startDate, endDate, image }) => {
  return (
    <div className="w-full flex justify-center mt-8">
      <div className="max-w-5xl w-full">
        <div className="flex flex-row flex-wrap space-x-2 items-center">
          <img 
            src={image} 
            alt={`${company} logo`} 
            className={`w-6 h-6 rounded-md object-contain ${company === "Apple" ? "invert" : ""} mr-2`} 
          />
          <p data-cursor="text" className="text-white text-[18px] md:text-[20px] font-diatype text-left" style={{ lineHeight: '1.6' }}>{company}</p>
          <span className="text-white">•</span>
          <p data-cursor="text" className="text-white text-[18px] md:text-[20px] font-diatype text-left opacity-60" style={{ lineHeight: '1.6' }}>{title}</p>
          <div className="hidden md:block flex-grow"></div>
          <p data-cursor="text" className="hidden md:block text-white text-[16px] md:text-[18px] font-diatype text-right opacity-40" style={{ lineHeight: '1.6' }}>{startDate} - {endDate}</p>
        </div>
        <p data-cursor="text" className="text-white text-[18px] md:text-[20px] font-diatype mt-2 text-left opacity-60 md:w-[70%]" style={{ lineHeight: '1.6' }}>{description}</p>
        <p data-cursor="text" className="md:hidden text-white text-[16px] font-diatype text-left opacity-40 mt-2" style={{ lineHeight: '1.6' }}>{startDate} - {endDate}</p>
      </div>
    </div>
  );
});

function App() {
  const config = {};
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isHovering, setIsHovering] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!cardRef.current || !isHovering) return;

      const rect = cardRef.current.getBoundingClientRect();
      // Calculate position relative to card center
      const x = (e.clientX - (rect.left + rect.width / 2)) / (rect.width / 2);
      const y = (e.clientY - (rect.top + rect.height / 2)) / (rect.height / 2);
      setMousePosition({ x, y });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [isHovering]);

  // Calculate transforms for each image
  const getTransform = (baseRotation, intensity = 1) => {
    if (!isHovering) {
      return `rotate(${baseRotation}deg)`;
    }
    const moveX = mousePosition.x * 15 * intensity;
    const moveY = mousePosition.y * 15 * intensity;
    return `translate(${moveX}px, ${moveY}px) rotate(${baseRotation}deg)`;
  };

  useEffect(() => {
    // Initialize cursor immediately
    initCursor(config);
    updateCursor();

    const handleResize = () => {
      if (window.innerWidth <= 768) {
        console.log("Cursor disposed");
        disposeCursor();
      } else {
        initCursor(config);
      }
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      // Clean up cursor when component unmounts
      disposeCursor();
    };
  }, []);

  return (
    <div className="bg-[#171717] min-h-screen flex flex-col pb-10 md:pb-20">
      <div className="max-w-5xl w-full flex flex-col px-4 md:px-6 mb-10 md:mb-20 mt-10 md:mt-20 mx-auto">
        <DynamicIsland />

        <motion.div 
          className="w-[1px] bg-white opacity-40 rounded-full mx-auto mb-8 md:mb-12" 
          initial={{ height: 0 }}
          animate={{ height: '100px' }}
          transition={{ 
            duration: 0.6, 
            ease: "easeInOut",
            delay: 0.6
          }}
        ></motion.div>

        <div className="flex flex-col md:flex-row items-center w-full gap-8 justify-center">
          <div className="w-full md:w-1/2 text-center">
            <Profile />
          </div>
          <div className="w-full md:w-1/2 px-4 md:px-0">
            <About />
          </div>
        </div>
      </div>

      <motion.div 
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.6, ease: "easeInOut", delay: 3.4 }}
        className="flex flex-col md:flex-row gap-8 justify-center w-full px-4 md:px-6 max-w-5xl mx-auto"
      >
        <Card
          link="https://usestream.app"
          title="Stream" 
          subtitle="Record your thoughts, ideas, and memories - recall them later." 
          className="bg-[#000000] pt-6 md:pt-10 w-full md:max-w-[600px]"
        >
          <div className="relative mb-4">
            <img src="/work-stream.png" alt="Stream" className="max-h-[300px] md:max-h-[500px] object-cover mx-auto" />
            <div className="absolute bottom-0 left-0 right-0 h-[100px] bg-gradient-to-t from-black to-transparent"></div>
          </div>
        </Card>
        
        <Card 
          title="Rabbithole" 
          subtitle="A launchpad for your curiosity - learn something new." 
          className="bg-[#111111] pt-6 md:pt-10 w-full md:max-w-[600px]"
          link="https://rabbithole.chat"
          ref={cardRef}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => {
            setIsHovering(false);
            setMousePosition({ x: 0, y: 0 });
          }}
        >
          <div className="relative mb-4">
            <div className="flex flex-col h-[300px] md:h-[500px] overflow-hidden pt-6 md:pt-10">
              <div className="flex flex-row">
                <img 
                  src="/rh-1.png" 
                  alt="Rabbithole 1" 
                  className="h-[150px] object-cover transition-transform duration-200 ease-out" 
                  style={{ transform: getTransform(4, 0.8) }}
                />
                <div className="flex-grow"></div>
                <img 
                  src="/rh-2.png" 
                  alt="Rabbithole 2" 
                  className="h-[150px] object-cover transition-transform duration-200 ease-out" 
                  style={{ transform: getTransform(-4, 0.8) }}
                />
              </div>

              <div className="flex-grow"></div>

              <img 
                src="/rh-logo.png" 
                alt="Rabbithole Logo" 
                className="h-[120px] object-contain"
              />

              <div className="flex-grow"></div>

              <div className="flex flex-row">
                <img 
                  src="/rh-3.png" 
                  alt="Rabbithole 3" 
                  className="h-[150px] object-cover transition-transform duration-200 ease-out" 
                  style={{ transform: getTransform(-4, 0.8) }}
                />
                <div className="flex-grow"></div>
                <img 
                  src="/rh-4.png" 
                  alt="Rabbithole 4" 
                  className="h-[150px] object-cover transition-transform duration-200 ease-out" 
                  style={{ transform: getTransform(4, 0.8) }}
                />
              </div>
            </div>

            <div className="absolute bottom-[-1px] right-[-2%] left-[-2%] h-[100px] bg-gradient-to-t from-[#111111] to-transparent"></div>
          </div>
        </Card>
      </motion.div>

      <PreviouslyWorked />
    </div>
  );
}

export default App;
